import { graphql } from "gatsby";
import React from "react";
import ArticlePreview from "../components/articlepreview";
import ArticlePreviewMini from "../components/articlepreviewmini";
import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = ({ data }: any) => {
  const articleData = data.allPrismicArticle.edges;

  articleData.sort(
    (a: any, b: any) =>
      new Date(b.node.data.date).valueOf() -
      new Date(a.node.data.date).valueOf()
  );

  return (
    <Layout>
      <SEO title="Blog" description="" lang="en" meta={[]} />
      <section className="bg-darkened mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mb-5">
              {/* Featured News - Large Previews */}
              <h3>Featured News</h3>
              {/* Display Every Other Item Starting from 0 */}
              {articleData.map((item: any, index: number) => {
                if (index % 2 === 0)
                  return (
                    <ArticlePreview data={item.node} key={item.node.uid} />
                  );
              })}
            </div>

            <div className="col-lg-4">
              {/* More News - Small Previews */}
              {articleData.length > 1 && <h3>More News</h3>}
              {/* Display Every Other Item Starting from 1 */}
              {articleData.map((item: any, index: number) => {
                if (index % 2 !== 0)
                  return (
                    <ArticlePreviewMini data={item.node} key={item.node.uid} />
                  );
              })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicArticle {
      edges {
        node {
          uid
          tags
          data {
            title {
              text
            }
            header_image {
              url
            }
            date
            author {
              text
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
